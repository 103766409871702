
import { useEvent } from '@/composable/useEvent'
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject, ref } from 'vue'
import QForm from 'quasar/src/components/form/QForm.js';
import { EventTableItem } from '@/types/event'

export default defineComponent({
  name: 'EventOpenModal',

  setup() {
    const { loading, open } = useEvent()
    const formRef = ref<QForm | null>(null)
    const openModal = inject<UseModal<EventTableItem>>('open-modal')

    async function onConfirm() {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      if (!openModal?.state.data) {
        throw new Error('Ingen event uuid i closeModal data parametrar')
      }
      open(openModal.state.data.uuid)
      openModal.closeModal()
    }

    return {
      openModal,
      loading,
      onConfirm,
      formRef,
    }
  },
})
