import { Ref, ref, ComputedRef, computed } from 'vue'
import { getErrorRemedies } from '@/api/error-remedy/getErrorRemedies'
import { useLoading, Loading } from '@/composable/useLoading'
import { ErrorRemedy } from '@/types/error-remedy'

interface UseErrorRemedy {
  data: Ref<ErrorRemedy[]>
  fetchAll: () => Promise<ErrorRemedy[]>
  loading: ComputedRef<Loading>
}

export function useErrorRemedy(initFetchAll?: boolean): UseErrorRemedy {
  const data = ref<ErrorRemedy[]>([])
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<ErrorRemedy[]>((resolve, reject) => {
      getErrorRemedies()
        .then(({ data: errorRemedies }) => {
          data.value = errorRemedies
          resolve(errorRemedies)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    loading: computed(() => state),
  }
}
