
import {
  defineComponent,
  ref,
  computed,
  watch,
  inject,
  onBeforeMount,
} from 'vue'
import AppFieldSelect from '@/components/AppFieldSelect.vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import {
  required as requiredRule,
  validDateRule,
  minCharactersRule,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { useReason } from '@/composable/useReason'
import { useEvent } from '@/composable/useEvent'
import { Reason } from '@/types/reason'
import { Event } from '@/types/event'
import { UseModal } from '@/composable/useModal'
import { useVehicle } from '@/composable/useVehicle'
import { format } from 'date-fns'
import { CausingVehicle } from '@/types/causing-vehicle'
import { useProfile } from '@/composable/useProfile'
import { useErrorRemedy } from '@/composable/useErrorRemedy'
import QForm from 'quasar/src/components/form/QForm.js';import QSelect from 'quasar/src/components/select/QSelect.js';
import { useXod } from '@/composable/useXod'
import { getMpkEvent } from '@/api/mpk/getMpkEvent'
import {
  getVehicleDefectByVehicle,
  VehicleDefectByVehicle,
} from '@/api/vehicle-defect/getVehicleDefectByVehicle'

interface Acc {
  [key: string]: string
}

const FORD_MIN_MAX_LENGTH = 8

export default defineComponent({
  name: 'EventFormModal',

  components: {
    AppFieldSelect,
    AppFieldDate,
  },

  setup() {
    const formModal = inject<UseModal>('form-modal')
    const { data: xodData, search: searchXod, loading: xodLoading } = useXod()
    const { data: reasons, loading: loadingStateReasons } = useReason(true)
    const {
      list: events,
      data: event,
      update: updateEvent,
      loading: loadingStateEvents,
      add: createEvent,
      fetchAll: fetchAllEvents,
      period,
    } = useEvent()
    const noTrvId = ref('')
    const showHint = ref(false)
    const { data: vehicles, loading: loadingStateVehicles } = useVehicle(true)
    const { data: errorRemedies, loading: loadingStateErrorRemedy } =
      useErrorRemedy(true)
    const { projectDependentInputFields } = useProfile()
    const formRef = ref<QForm | null>(null)

    const reason1 = ref<string | null>(null)
    const reason2 = ref<string | null>(null)
    const reason3 = ref<string | null>(null)

    const isFordonsskada = computed(() => reason1.value === 'Fordonsskada')

    const disabledAddCausingVehicleButton = computed(() => {
      if (!isFordonsskada.value) return true

      if (!causingVehicle.value.vehicleUuid) return true

      if (
        projectDependentInputFields.value &&
        projectDependentInputFields.value?.ford
      ) {
        const causingVehicleFord = (causingVehicle.value?.ford || '').toString()
        return (
          causingVehicleFord && causingVehicleFord.length < FORD_MIN_MAX_LENGTH
        )
      }

      return false
    })

    const columnsCausingVehicles = computed(() => {
      const table = [
        {
          name: 'vehicleUuid',
          label: 'Orsakande fordon',
          align: 'left',
          field: 'vehicleUuid',
          sortable: true,
        },
      ]

      if (!projectDependentInputFields.value) return table

      if (projectDependentInputFields.value?.ford) {
        table.push({
          name: 'ford',
          label: 'Skadenummer ford',
          align: 'left',
          field: 'ford',
          sortable: true,
        })
      }

      if (projectDependentInputFields.value?.mims) {
        table.push({
          name: 'mims',
          label: 'Skadenummer mims',
          align: 'left',
          field: 'mims',
          sortable: true,
        })
      }

      return [
        ...table,
        {
          name: '_action',
          label: '',
          align: 'right',
          field: '_action',
          sortable: false,
        },
      ]
    })

    const model = ref<Partial<Event>>({
      uuid: uuidv4(),
      reasonUuid: null,
      linkedEventUuid: null,
      start: format(new Date(), 'yyyy-MM-dd'),
      description: '',
      information: '',
      errorRemedyUuid: null,
      hhf: false,
      xod: null,
      causingVehicles: [],
    })

    // If update modal set event to model
    onBeforeMount(async () => {
      if (event.value && formModal?.state.mode === 'update') {
        model.value = Object.assign(
          {},
          {
            ...event.value,
            causingVehicles: event.value.causingVehicles.map((x) =>
              Object.assign(
                {
                  _filter: '',
                  _vehicleDefects: [{ defect_number: x.ford }].filter(
                    (x) => x.defect_number
                  ),
                },
                x
              )
            ),
          }
        )
        reason1.value = event.value.reason.reason1
        reason2.value = event.value.reason.reason2
        reason3.value = event.value.reason.reason3
        causingVehicle.value.eventUuid = event.value.uuid
      }

      watch(
        () => model.value.reasonUuid,
        () => {
          model.value.hhf = false
          model.value.errorRemedyUuid = null
          causingVehicle.value = creatCausingVehicleModel()
        }
      )
    })

    function creatCausingVehicleModel(): CausingVehicle {
      return {
        _filter: '',
        _vehicleDefects: [],
        eventUuid: model.value.uuid || '',
        mims: null,
        ford: null,
        vehicleUuid: '',
      }
    }
    const isMandatoryLinkedEvent = computed(() => {
      if (reason2.value && reason2.value === 'Personalbrist pga trafikstörning')
        return true
      if (!model.value.reasonUuid) return false
      const reason = reasons.value.find(
        (x) => x.uuid === model.value.reasonUuid
      )
      if (!reason) return false
      return reason.mandatory_parent_event
    })

    const causingVehicle = ref<CausingVehicle>(creatCausingVehicleModel())

    const isPenalty = ref(false)

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      if (model.value.causingVehicles && model.value.causingVehicles.length) {
        model.value.causingVehicles = model.value.causingVehicles.filter(
          (x) => x.vehicleUuid
        )
      } else {
        model.value.causingVehicles = []
      }

      if (formModal?.state.mode === 'create') {
        await createEvent(model.value)
      }

      if (formModal?.state.mode === 'update') {
        await updateEvent(model.value)
      }

      await fetchAllEvents(period.value)

      formModal?.closeModal()
    }

    watch(
      () => model.value.xod,
      (v) => {
        if (typeof v === 'string') {
          model.value.xod = null
        }
      }
    )

    const reasons1 = computed(() => {
      return Object.values(
        reasons.value.reduce<Acc>((acc, reason) => {
          const key = reason.reason1 as keyof Reason
          if (!acc[key]) {
            acc[key] = reason.reason1
          }
          return acc
        }, {})
      )
    })

    const reasons2 = computed(() => {
      if (!reason1.value) {
        return [
          ...new Set(
            reasons.value
              .slice()
              .sort((a, b) => (a.reason2 < b.reason2 ? -1 : 1))
              .map((reason) => reason.reason2)
          ),
        ]
      }

      return Object.values(
        reasons.value.reduce<Acc>((acc, reason) => {
          if (reason.reason1 !== reason1.value) return acc
          const key = reason.reason2 as keyof Reason
          if (!acc[key]) {
            acc[key] = reason.reason2
          }
          return acc
        }, {})
      )
    })

    const reasons3 = computed(() => {
      if (!reason1.value && !reason2.value) {
        return [
          ...new Set(
            reasons.value
              .slice()
              .sort((a, b) => (a.reason3 < b.reason3 ? -1 : 1))
              .map((reason) => reason.reason3)
          ),
        ]
      }

      return Object.values(
        reasons.value.reduce<Acc>((acc, reason) => {
          if (
            reason.reason1 !== reason1.value ||
            reason.reason2 !== reason2.value
          )
            return acc
          const key = reason.reason3 as keyof Reason
          if (!acc[key]) {
            acc[key] = reason.reason3
          }
          return acc
        }, {})
      )
    })

    watch(reason2, () => {
      if (!reason1.value) {
        const reason = reasons.value.find(
          (reason) => reason.reason2 === reason2.value
        )

        if (reason) {
          reason1.value = reason.reason1
        }
      }
    })

    watch(reason3, () => {
      if (!reason1.value && !reason2.value) {
        const reason = reasons.value.find(
          (reason) => reason.reason3 === reason3.value
        )

        if (reason) {
          reason1.value = reason.reason1
          reason2.value = reason.reason2
        }
      }

      if (reason1.value && reason2.value && reason3.value) {
        const reason = reasons.value.find(
          (reason) =>
            reason.reason1 === reason1.value &&
            reason.reason2 === reason2.value &&
            reason.reason3 === reason3.value
        )
        if (reason) {
          model.value.reasonUuid = reason.uuid
          model.value.description = reason.description
          isPenalty.value = reason.penalty
        }
      } else {
        model.value.reasonUuid = null
        isPenalty.value = false
      }
    })

    function addCausingVehicle() {
      if (!model.value.causingVehicles) return

      model.value.causingVehicles.push(causingVehicle.value)

      causingVehicle.value = creatCausingVehicleModel()
    }

    function removeCausingVehicle(rowIndex: number) {
      if (!model.value.causingVehicles) return

      model.value.causingVehicles = model.value.causingVehicles.filter(
        (causingVehicle, i) => i !== rowIndex
      )
    }

    function findVehicleNameByUuid(vehicleUuid: string) {
      if (!vehicles.value) return ''
      const vehicle = vehicles.value.find(
        (vehicle) => vehicle.uuid === vehicleUuid
      )
      return vehicle ? vehicle.name : ''
    }

    const loading = computed(() => {
      return (
        loadingStateEvents.value.create ||
        loadingStateEvents.value.update ||
        loadingStateEvents.value.getAll
      )
    })

    function onUpdateReason(reasonProp: 'reason1' | 'reason2' | 'reason3') {
      if (reasonProp === 'reason1') {
        reason2.value = null
        reason3.value = null
      }

      if (reasonProp === 'reason2') {
        reason3.value = null
      }
    }

    function updateFordNumber(fordNumber: string, index: number) {
      if (!model.value.causingVehicles) return
      model.value.causingVehicles[index].ford = fordNumber
        ? parseInt(`${fordNumber}`.substring(0, 8), 10)
        : null
    }

    function updateMimsNumber(mimsNumber: string, index: number) {
      if (!model.value.causingVehicles) return
      model.value.causingVehicles[index].mims = parseInt(mimsNumber, 10)
    }

    function updateVehicleUuid(vehicleUuid: string, index: number) {
      if (!model.value.causingVehicles) return
      model.value.causingVehicles[index].vehicleUuid = vehicleUuid

      searchVehicleDefect(vehicleUuid, index)
    }

    const shouldAddCausingVehicle = ref(
      Boolean(formModal?.state.mode === 'update')
    )

    watch(isFordonsskada, (v) => {
      if (shouldAddCausingVehicle.value) {
        shouldAddCausingVehicle.value = false
        return
      }
      if (v) {
        addCausingVehicle()
      } else {
        model.value.causingVehicles = []
      }
    })

    const onFilterXod = async (value: unknown) => {
      const xod = Number(value)
      if (typeof xod === 'number' && value) {
        await searchXod(xod)
      } else {
        xodData.value = []
      }
    }

    const createTrvValue: QSelect['onNewValue'] = (val, done) => {
      // specific logic to eventually call done(...) -- or not
      done({ trvId: val, eventUuid: model.value.uuid }, 'add-unique')
      getMpkEvent(val).then((res) => {
        if (!res.data) {
          noTrvId.value = val
        } else {
          noTrvId.value = ''
        }
      })
      // done callback has two optional parameters:
      //  - the value to be added
      //  - the behavior (same values of new-value-mode prop,
      //    and when it is specified it overrides that prop –
      //    if it is used); default behavior (if not using
      //    new-value-mode) is to add the value even if it would
      //    be a duplicate
    }

    const createDefectNumber: QSelect['onNewValue'] = (val, done) => {
      if (!val) return
      done({ defect_number: parseInt(val, 10) }, 'add-unique')
    }

    const searchingVehicleDefect = ref(false)
    const vehicleDefectsSuggestions = ref<VehicleDefectByVehicle[]>([])

    const searchVehicleDefect = async (
      vehicle_uuid: string,
      rowIndex: number
    ) => {
      searchingVehicleDefect.value = false
      const vehicle = vehicles.value.find((x) => x.uuid === vehicle_uuid)
      if (!vehicle || !model.value.start) return

      searchingVehicleDefect.value = true
      const { data } = await getVehicleDefectByVehicle(vehicle.name, {
        timestamp: `${format(new Date(model.value.start), 'yyyy-MM-dd')}T23:59`,
      })
      searchingVehicleDefect.value = false
      if (!model.value.causingVehicles) return

      model.value.causingVehicles[rowIndex]._vehicleDefects = [
        ...data.map((x) => ({
          ...x,
          defect_date_and_time: format(
            new Date(x.defect_date_and_time),
            'yyyy-MM-dd HH:mm'
          ),
        })),
        ...(
          model.value?.causingVehicles[rowIndex]._vehicleDefects || []
        ).filter((x) => Object.keys(x).length === 1),
      ]
    }

    const vehicleDefectSuggenstionsFiltered = computed(() => {
      return vehicleDefectsSuggestions.value
    })

    const onClearFordNumber = (rowIndex: number) => {
      if (!model.value.causingVehicles) return
      model.value.causingVehicles[rowIndex].ford = null
      if (model.value.causingVehicles[rowIndex]._vehicleDefects) {
        model.value.causingVehicles[rowIndex]._vehicleDefects =
          model.value.causingVehicles[rowIndex]._vehicleDefects?.filter(
            (x) => Object.keys(x).length > 1
          )
      }
    }

    return {
      minFordLengthRule: minCharactersRule(FORD_MIN_MAX_LENGTH),
      isPenalty,
      formModal,
      onSubmit,
      formRef,
      model,
      requiredRule,
      validDateRule,
      loadingStateReasons,
      loading,
      loadingStateEvents,
      loadingStateVehicles,
      events,
      reasons1,
      reasons2,
      reasons3,
      reason1,
      reason2,
      reason3,
      vehicles,
      causingVehicle,
      columnsCausingVehicles,
      addCausingVehicle,
      removeCausingVehicle,
      findVehicleNameByUuid,
      projectDependentInputFields,
      isFordonsskada,
      disabledAddCausingVehicleButton,
      loadingStateErrorRemedy,
      errorRemedies,
      FORD_MIN_MAX_LENGTH,
      onUpdateReason,
      updateFordNumber,
      updateMimsNumber,
      updateVehicleUuid,
      xodData,
      onFilterXod,
      xodLoading,
      createTrvValue,
      isMandatoryLinkedEvent,
      noTrvId,
      onClearFordNumber,
      vehicleDefectSuggenstionsFiltered,
      searchingVehicleDefect,
      searchVehicleDefect,
      createDefectNumber,
      showHint,
    }
  },
})
