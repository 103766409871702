import { instance } from '@/services/avik-api-gateway'
import { Xod } from '@/types/xod'
import { AxiosResponse } from 'axios'

export function search(
  trafficDisturbanceId: number,
  controller: AbortController
): Promise<AxiosResponse<Xod[]>> {
  return instance.get(`/xod?trafficDisturbanceId=${trafficDisturbanceId}`, {
    signal: controller.signal,
  })
}
