import { Xod } from '@/types/xod'
import { Ref, ref } from 'vue'
import { search as xodSearch } from '@/api/xod/search'

interface UseXod {
  data: Ref<Xod[]>
  search: (trafficDisturbanceId: number) => void
  loading: Ref<boolean>
  controller: AbortController
}

export const useXod = (): UseXod => {
  const data = ref<Xod[]>([])
  const loading = ref(false)
  let controller = new AbortController()

  const search: UseXod['search'] = async (trafficDisturbanceId) => {
    controller.abort()
    controller = new AbortController()
    loading.value = true
    try {
      const { data: xodData } = await xodSearch(
        trafficDisturbanceId,
        controller
      )
      data.value = xodData
    } catch (err) {
      data.value = []
    }

    loading.value = false
  }

  return {
    data,
    search,
    loading,
    controller,
  }
}
