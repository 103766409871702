
import AppFieldDate from '@/components/AppFieldDate.vue'
import {
  validDateRule,
  required as requiredRule,
} from '@/common/formValidationRules'
import { useEvent } from '@/composable/useEvent'
import { UseModal } from '@/composable/useModal'
import format from 'date-fns/format'
import { computed, defineComponent, inject, ref } from 'vue'
import QForm from 'quasar/src/components/form/QForm.js';
import { EventTableItem } from '@/types/event'

export default defineComponent({
  name: 'EventCloseModal',

  components: {
    AppFieldDate,
  },

  setup() {
    const { loading, close, data: event } = useEvent()
    const formRef = ref<QForm | null>(null)
    const closeModal = inject<UseModal<EventTableItem>>('close-modal')
    const todayDate = format(new Date(), 'yyyy-MM-dd')
    const todayTime = format(new Date(), 'HH:mm')
    const date = ref(todayDate)
    const time = ref(todayTime)

    async function onConfirm() {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      if (!closeModal?.state.data) {
        throw new Error('Ingen event uuid i closeModal data parametrar')
      }
      close({
        eventUuid: closeModal.state.data.uuid,
        closedAt: `${date.value} ${time.value}`,
      })
      closeModal.closeModal()
    }

    const canNotCloseEvent = computed(() => {
      return !isXod.value && isDeviationDisruptions.value
    })

    const isXod = computed(() => {
      return closeModal?.state?.data?.xod || false
    })

    const isDeviationDisruptions = computed(() => {
      return event.value
        ? Boolean(event.value.deviationDisruptions.length)
        : false
    })

    return {
      requiredRule,
      validDateRule,
      date,
      time,
      closeModal,
      loading,
      onConfirm,
      formRef,
      canNotCloseEvent,
      isXod,
      isDeviationDisruptions,
    }
  },
})
