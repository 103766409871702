import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'

export interface VehicleDefectByVehicle {
  defect_date_and_time: string
  defect_heading: string
  defect_number: number
  vehicle_number: string
}

export function getVehicleDefectByVehicle(
  vehicleName: string,
  params: {
    timestamp?: string
    top?: string
  }
): Promise<AxiosResponse<VehicleDefectByVehicle[]>> {
  return instance.get(`/vehicle-defect/${vehicleName}`, {
    params,
  })
}
