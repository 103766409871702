
import { UseModal } from '@/composable/useModal'
import { EventTableItem } from '@/types/event'
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, inject, ref } from 'vue'
import { required as requiredRule } from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { useEvent } from '@/composable/useEvent'
import { format } from 'date-fns'

export default defineComponent({
  name: 'EventPenaltyAssessmentModal',

  setup() {
    const showConfirmDeleteModal = ref(false)
    const penaltyAssessmentModal = inject<UseModal<EventTableItem>>(
      'penalty-assessment-modal'
    )
    const formRef = ref<QForm>()
    const {
      createPenaltyAssessment,
      updatePenaltyAssessment,
      deletePenaltyAssessment,
      loading,
      penaltyAssessment,
    } = useEvent()
    const model = ref({
      uuid: uuidv4(),
      eventUuid: penaltyAssessmentModal?.state?.data?.uuid || '',
      penalty: false,
      comment: '',
    })

    const lastUpdated = penaltyAssessment.value
      ? penaltyAssessment.value.logs[penaltyAssessment.value.logs.length - 1]
      : null

    const mode = penaltyAssessment.value ? 'update' : 'create'

    if (penaltyAssessment.value) {
      model.value.uuid = penaltyAssessment.value.uuid
      model.value.penalty = penaltyAssessment.value.penalty
      model.value.comment = penaltyAssessment.value.comment
    }

    const closeModal = () => {
      penaltyAssessmentModal?.closeModal()
    }

    const onConfirm = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      if (mode === 'create') {
        await createPenaltyAssessment(model.value)
      } else {
        await updatePenaltyAssessment(model.value.uuid, model.value.eventUuid, {
          penalty: model.value.penalty,
          comment: model.value.comment,
        })
      }

      closeModal()
    }

    const onRemove = async () => {
      await deletePenaltyAssessment(model.value.uuid, model.value.eventUuid)
      showConfirmDeleteModal.value = false
      closeModal()
    }

    const formatDate = (d: string) => format(new Date(d), 'yyyy-MM-dd HH:mm')

    return {
      closeModal,
      onConfirm,
      onRemove,
      formRef,
      model,
      requiredRule,
      showConfirmDeleteModal,
      loading,
      mode,
      lastUpdated,
      formatDate,
    }
  },
})
