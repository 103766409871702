
import { useEvent } from '@/composable/useEvent'
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'EventDeleteConfirmModal',

  emits: ['confirm'],

  setup(props, { emit }) {
    const { loading, remove } = useEvent()
    const deleteModal = inject<UseModal>('delete-modal')

    function onConfirm() {
      if (!deleteModal?.state.data) {
        throw new Error('Ingen event uuid i deleteModal data parametrar')
      }
      remove(deleteModal.state.data)
      deleteModal.closeModal()
      emit('confirm')
    }

    return {
      deleteModal,
      loading,
      onConfirm,
    }
  },
})
