
import { useEvent } from '@/composable/useEvent'
import { UseModal } from '@/composable/useModal'
import { Log } from '@/types/log'
import { format } from 'date-fns'
import { defineComponent, inject } from 'vue'
import orderBy from 'lodash.orderby'

export default defineComponent({
  name: 'EventLogModal',

  props: {
    eventId: Number,
  },

  setup() {
    const { log } = useEvent()
    const logModal = inject<UseModal>('log-modal')

    const columns = [
      {
        align: 'left',
        name: 'method',
        required: true,
        label: 'Typ',
        field: 'method',
        sortable: false,
      },
      {
        align: 'left',
        name: 'created',
        required: true,
        label: 'Loggdatum',
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd HH:mm'),
        field: 'created',
        sortable: false,
      },
      {
        align: 'left',
        name: 'user',
        required: true,
        label: 'Användare',
        field: (v: Log) => v?.user?.email,
        sortable: false,
      },
      {
        align: 'left',
        name: 'was',
        required: true,
        label: 'Innan ändring',
        field: (v: Log) => JSON.stringify(v?.change?.before, null, 2),
        sortable: false,
      },
      {
        align: 'left',
        name: 'change',
        required: true,
        label: 'Efter ändring',
        field: (v: Log) => JSON.stringify(v?.change?.after, null, 2),
        sortable: false,
      },
    ]

    const sortedLog = orderBy(log.value, ['created'], ['desc'])

    return { logModal, sortedLog, columns, orderBy }
  },
})
