<template>
  <q-card style="min-width: 400px">
    <q-card-section class="row items-center text-h6">
      Ta bort händelsen
    </q-card-section>
    <q-card-section>
      <p>Är du säker på att du vill ta bort händelsen?</p>
    </q-card-section>
    <q-card-actions align="right">
      <q-btn
        flat
        label="Avbryt"
        @click="deleteModal.closeModal"
        type="button"
        :disable="loading.delete"
      />
      <q-btn
        label="Ta bort"
        type="button"
        color="red"
        :loading="loading.delete"
        @click="onConfirm"
      />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { useEvent } from '@/composable/useEvent'
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'EventDeleteConfirmModal',

  emits: ['confirm'],

  setup(props, { emit }) {
    const { loading, remove } = useEvent()
    const deleteModal = inject<UseModal>('delete-modal')

    function onConfirm() {
      if (!deleteModal?.state.data) {
        throw new Error('Ingen event uuid i deleteModal data parametrar')
      }
      remove(deleteModal.state.data)
      deleteModal.closeModal()
      emit('confirm')
    }

    return {
      deleteModal,
      loading,
      onConfirm,
    }
  },
})
</script>
