<template>
  <q-card style="min-width: 400px">
    <q-form ref="formRef" @submit.prevent="onConfirm" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6">
        Avsluta händelsen
      </q-card-section>

      <q-card-section v-if="!canNotCloseEvent">
        <p>Är du säker på att du vill avsluta händelsen?</p>
        <div class="row">
          <AppFieldDate label="Datum" v-model="date" :rules="[validDateRule]" />
          <q-input
            outlined
            square
            v-model="time"
            label="Tid"
            type="time"
            :rules="[requiredRule]"
          />
        </div>
      </q-card-section>
      <q-card-section v-else>
        <p>Kan inte avsluta händelsen</p>
        <p v-if="isDeviationDisruptions" class="text-weight-bold">
          <q-icon name="mdi-alert" color="negative" />
          XOD händelseid saknas
        </p>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="closeModal.closeModal"
          type="button"
          :disable="loading.delete"
        />
        <q-btn
          v-if="!canNotCloseEvent"
          label="Avsluta"
          type="submit"
          style="color: red"
          :loading="loading.delete"
          @click="onConfirm"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import AppFieldDate from '@/components/AppFieldDate.vue'
import {
  validDateRule,
  required as requiredRule,
} from '@/common/formValidationRules'
import { useEvent } from '@/composable/useEvent'
import { UseModal } from '@/composable/useModal'
import format from 'date-fns/format'
import { computed, defineComponent, inject, ref } from 'vue'
import { QForm } from 'quasar'
import { EventTableItem } from '@/types/event'

export default defineComponent({
  name: 'EventCloseModal',

  components: {
    AppFieldDate,
  },

  setup() {
    const { loading, close, data: event } = useEvent()
    const formRef = ref<QForm | null>(null)
    const closeModal = inject<UseModal<EventTableItem>>('close-modal')
    const todayDate = format(new Date(), 'yyyy-MM-dd')
    const todayTime = format(new Date(), 'HH:mm')
    const date = ref(todayDate)
    const time = ref(todayTime)

    async function onConfirm() {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      if (!closeModal?.state.data) {
        throw new Error('Ingen event uuid i closeModal data parametrar')
      }
      close({
        eventUuid: closeModal.state.data.uuid,
        closedAt: `${date.value} ${time.value}`,
      })
      closeModal.closeModal()
    }

    const canNotCloseEvent = computed(() => {
      return !isXod.value && isDeviationDisruptions.value
    })

    const isXod = computed(() => {
      return closeModal?.state?.data?.xod || false
    })

    const isDeviationDisruptions = computed(() => {
      return event.value
        ? Boolean(event.value.deviationDisruptions.length)
        : false
    })

    return {
      requiredRule,
      validDateRule,
      date,
      time,
      closeModal,
      loading,
      onConfirm,
      formRef,
      canNotCloseEvent,
      isXod,
      isDeviationDisruptions,
    }
  },
})
</script>
