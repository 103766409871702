<template>
  <q-card style="min-width: 400px">
    <q-form ref="formRef" @submit.prevent="onConfirm" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6">
        Aktivera händelsen
      </q-card-section>

      <q-card-section>
        <p>Är du säker på att du vill återaktivera händelsen?</p>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="openModal.closeModal"
          type="button"
          :disable="loading.update"
        />
        <q-btn
          label="Aktivera"
          type="submit"
          color="primary"
          :loading="loading.update"
          @click="onConfirm"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { useEvent } from '@/composable/useEvent'
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject, ref } from 'vue'
import { QForm } from 'quasar'
import { EventTableItem } from '@/types/event'

export default defineComponent({
  name: 'EventOpenModal',

  setup() {
    const { loading, open } = useEvent()
    const formRef = ref<QForm | null>(null)
    const openModal = inject<UseModal<EventTableItem>>('open-modal')

    async function onConfirm() {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      if (!openModal?.state.data) {
        throw new Error('Ingen event uuid i closeModal data parametrar')
      }
      open(openModal.state.data.uuid)
      openModal.closeModal()
    }

    return {
      openModal,
      loading,
      onConfirm,
      formRef,
    }
  },
})
</script>
